import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import MenuNavLinks from "./MenuNavLInks";
import { quote } from "../site-data/components/MenuQuote";

const Menu = ({ menuOpen }) => {
  const ref = useRef();

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("nav-active");
      return;
    }
    setTimeout(() => {
      ref.current.classList.add("menu-hidden");
    }, 200);
    document.body.classList.remove("nav-active");
  }, [menuOpen]);

  return (
    <div ref={ref} className={`bg-secondary m-0 p-0 fixed-top h-100  menu-container nav-element-hidden ${menuOpen ? "in" : "out"} `}>
      <div className="row h-100 overflow-scroll">
        <div
          className="col-lg-5  h-100 menu-left-column"
          //   style={{ flex: "0 0 400px" }}
        >
          <div className="nav-link-spacer ms-lg-4">
            <MenuNavLinks />
          </div>
        </div>
        <div className="col-lg p-0  menu-right-column  d-flex justify-content-md-center">
          <div className="menu-right-container-background"></div>
          <div className="menu-right-innner-container d-flex justify-content-center ">
            <div className="container">
              <img src={"/img/quote.svg"} alt="Quote below" />
              <div className="text-center w-100 center quote">{quote.message}</div>
              <div className="text-center w-100 author text-uppercase ">
                {quote.author} from {quote.cityState}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
  };
};

export default connect(mapStateToProps)(Menu);
