import React, { useEffect, useState } from "react";
import CallUs from "./CallUs";
import OutlinedCard from "./OutlinedCard";
import { Link } from "react-router-dom";
import useWindowDemensions from "../helpersScripts/windowDimensions";

const ClientProcessComp = () => {
  const { width } = useWindowDemensions();
  let [scale, setScale] = useState(0);
  let [margin, setMargin] = useState(0);
  const breakPoint = 500;

  useEffect(() => {
    setScale(width < breakPoint ? 1 : 1.5);
    setMargin(width < breakPoint ? 4 : 5);
  }, [width]);

  return (
    <div
      className=" d-flex flex-column align-items-center justify-content-center pt-5"
      style={{ height: "650px", background: `url('/img/columns-court.jpg') center center/cover` }}
    >
      <OutlinedCard column="col-12 col-sm-8 col-md-7 col-lg-6 col-xl-4 text-center" key={0}>
        <h4 className="fw-bolder mb-3" style={{ fontFamily: "PT Serif Caption,serif" }}>
          Our Client Process
        </h4>
        <p className="text-center fw-light">
          For more than 25 years, we have been adjusting our process to the needs of our clients. From a free initial consultation through
          settlement or litigation, you can count on a team of compassionate advocates that care about your case.
        </p>
        <Link
          to={"/client-process"}
          className="btn btn-primary rounded-0 fw-lighter py-3 px-4 mt-4"
          alt="find out about our client process"
        >
          Learn More
        </Link>
      </OutlinedCard>
      <div className={`mt-${margin} text-white`} style={{ transform: `scale(${scale})` }}>
        <CallUs />
      </div>
    </div>
  );
};

export default ClientProcessComp;
