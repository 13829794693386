import React, { Fragment, useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Cookies from "js-cookie";
import jsonData from "../site-data/metdata.json";

// const jsonData = {
//   GUA: "<script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}  gtag('js', new Date()); gtag('config', 'UA-19508292-1'); gtag('event', 'page_view', { 'send_to': 'UA-74213435-1' }); gtag('event', 'page_view', { 'send_to': 'UA-179407215-1' });</script>",
//   GA: "<!-- Google Analytics --><script>(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', 'UA-128155972-1', 'auto');ga('send', 'pageview');</script><!-- End Google Analytics -->",
//   EventListeners:
//     "<script> document.querySelector('input.wpcf7-form-control.wpcf7-submit').addEventListener('click', function() {ga('send', 'event', 'form', 'submit', window.location.pathname);window.uetq = window.uetq || [];window.uetq.push({ 'ec':'form', 'ea':'submit', 'el':window.location.pathname});});</script><script>window.addEventListener('load', function() {document.querySelector('button.frm_button_submit').addEventListener('click', function() {ga(ga.getAll()[0].get('name') + '.send', 'event', 'contact', 'submit', 'form');})})</script>",
// };

const CookieConsentContainer = () => {
  let [consent, setConsent] = useState(false);

  useEffect(() => {
    if (Cookies.get("dataConsent") !== undefined && Cookies.get("dataConsent") === "true") {
      const loadData = () => JSON.parse(JSON.stringify(jsonData));
      const [header] = document.getElementsByTagName("head");

      if (loadData().GUA) header.insertAdjacentHTML("beforeend", loadData().GUA);
      if (loadData().GA) header.insertAdjacentHTML("beforeend", loadData().GA);
      if (loadData().EventListeners) header.insertAdjacentHTML("beforeend", loadData().EventListeners);
    }
  }, [consent]);

  const renderedElements = () => {
    return (
      <div className="container-fluid position-absolute p-0 bg-opacity-75" style={{ zIndex: "10000" }}>
        <div className="">
          <CookieConsent
            style={{ background: "rgba(0,0,0,.9)" }}
            disableStyles={true}
            location="top"
            overlay
            buttonText="I Accept"
            cookieName="dataConsent"
            buttonClasses="btn btn-info  px-5 py-2 m-1"
            declineButtonClasses="btn btn-outline-primary  px-5 py-2 m-1"
            enableDeclineButton
            containerClasses="p-5 shadow"
            contentClasses="col-lg-5 col-md-7"
            children={
              <div className=" d-flex flex-row pb-3">
                <div className="text-white text-left">
                  <p>Negem & Worthington uses cookies to enhance user experience and improve our website.</p>
                  <p className="fw-lighter " style={{ fontSize: ".75rem" }}>
                    We use cookies to optimize site functionality and give you the best possible experience.
                  </p>
                </div>
              </div>
            }
            onAccept={() => {
              setConsent(true);
            }}
          ></CookieConsent>
        </div>
      </div>
    );
  };

  return <div>{!consent ? renderedElements() : ""}</div>;
};

export default CookieConsentContainer;
