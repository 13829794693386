import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore } from "redux";
// import { configureStore } from "@reduxjs/toolkit";
import { BrowserRouter } from "react-router-dom";


import reducers from "./reducers";

import App from "./App/App";
import "./sass/app.scss";
import ScrollToTop from "./App/components/ScrollToTop";
import { useEffect } from 'react';

// const root2 = ReactDOM.createRoot(document.querySelector("#root")).render(
//   <Provider store={createStore(reducers)}>
//     <BrowserRouter>
//       <ScrollToTop />
//       <App />
//     </BrowserRouter>
//   </Provider>
// );

const rootContainer = document.querySelector("#root");
// const root = rootContainer.hasChildNodes
//   ? ReactDOM.hydrateRoot(
//       rootContainer,
//       <Provider store={createStore(reducers)}>
//         <BrowserRouter>
//           <ScrollToTop />
//           <App />
//         </BrowserRouter>
//       </Provider>
//     )
//   : ReactDOM.createRoot(rootContainer).render(
//       <Provider store={createStore(reducers)}>
//         <BrowserRouter>
//           <ScrollToTop />
//           <App />
//         </BrowserRouter>
//       </Provider>
//     );

// console.log(root);
const mainApp = (
  <Provider store={createStore(reducers)}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>
);
// const renderMethod = module.hot ? ReactDOM.createRoot(rootContainer).render : ReactDOM.hydrateRoot;

// renderMethod(
//   <Provider store={createStore(reducers)}>
//     <BrowserRouter>
//       <ScrollToTop />
//       <App />
//     </BrowserRouter>
//   </Provider>,
//   rootContainer
// );

if (rootContainer.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootContainer, mainApp);
} else {
  // ReactDOM.render(mainApp, rootContainer);
 
  ReactDOM.createRoot(rootContainer).render(mainApp);
}


