import link from "../links";

const jimmyNegem = {
  slug: "jimmy-negem",
  link: "/attorneys/jimmy-negem",
  image: "/img/Negem-center-bust.jpg",
  heroImage: "/img/Negem-bust.jpg",
  imgAlt: "Jimmy Negem in a nice suit with a smile",
  imageYAlign: "40",
  title: "Jimmy Negem",
  form: "DiscussYourCase",
  phone: "903-594-4466",
  email: "jimmy@negemlaw.com",
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    "Based in Tyler, Texas, attorney Jimmy Negem has been practicing personal injury law for over two decades, representing victims who have suffered injury in a wide variety of circumstances, ranging from serious auto accidents to medical malpractice ...",
  content: [
    <div className="row overview mb-5">
      <div className="h2 fw-light mb-4">Meet Jimmy</div>
      <p className="fw-light mt-2">
        Based in Tyler, Texas, attorney Jimmy Negem has been practicing personal injury law for over two decades, representing victims who
        have suffered injury in a wide variety of circumstances, ranging from serious auto accidents to medical malpractice.
      </p>
      <p className="fw-light mt-2">
        As part of the {link("Texas Trial Lawyers Association")} and the {link("American Association of Justice")}, Jimmy is committed to
        helping injured victims obtain justice in the courtroom. He is also an active member of the {link("Smith County Bar Association")}{" "}
        and the {link("State Bar of Texas")}. His extensive trial experience includes practicing before the U.S. District Court in the
        {link("Northern")}, {link("Southern")}, and {link("Eastern")} Districts of Texas.
      </p>
      <p className="fw-light mt-2">
        Jimmy received his undergraduate degree from {link("Texas A&M University")}, where he was a member of Delta Theta Phi Fraternity,
        and went on to attend the {link("South Texas College of Law")}. While in law school, his accomplishments earned him a position on
        the <i>South Texas Law Journal</i>.
      </p>
      <p className="fw-light mt-2">
        Jimmy has longstanding roots in East Texas. The Negem family is among Tyler’s founding families, settling in Texas in the mid-19th
        century. Today, Jimmy and his wife Michelle live in Tyler with their children and attend Grace Community Church.
      </p>
    </div>,

    <div className="row experience mb-5">
      <div className="h3 fw-light mb-2">Education</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("South Texas College of Law")}, Doctorate of Jurisprudence, (1982)</li>
          <li>{link("Texas A&M University")}, Bachelors of Business Administration, (1979)</li>
        </ul>
      </div>
    </div>,

    <div className="row admissions mb-5">
      <div className="h3 fw-light mb-2">Admissions</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
        </ul>
      </div>
    </div>,

    <div className="row professional mb-5">
      <div className="h3 fw-light mb-2">Professional Affiliations</div>
      <div className="ms-md-4 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>{link("State Bar of Texas")}</li>
          <li>{link("American Association of Justice")} (also known as the Association of Trial Lawyers of America)</li>
          <li>{link("Texas Trial Lawyers Association")}</li>
          <li>{link("Smith County Bar Association")}</li>
        </ul>
      </div>
    </div>,
  ],
};

export default jimmyNegem;
