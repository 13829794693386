import React from "react";
import { connect } from "react-redux";
import MainLogo from "./MainLogo";
import NavInfo from "./NavInfo";
import { fetchSearchMenuState } from "../../actions";
import MainSearchBar from "./MainSearchBar";

class NavBar extends React.Component {
  state = { position: 0, currentPosition: false };
  currentState = false;
  icon = "";
  hide = "d-none";

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleOutsideSearchClick = this.handleOutsideSearchClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("mousedown", this.handleOutsideSearchClick);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.addEventListener("mousedown", this.handleOutsideSearchClick);
  }
  handleOutsideSearchClick = (event) => {
    this.icon = document.querySelector(".nav-search-container");

    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !this.icon.contains(event.target)) {
      this.props.fetchSearchMenuState(false);
      if (!this.props.searchOpen) {
        setTimeout(() => {
          this.hide = "d-none";
        }, 2000);
      } else {
        this.hide = "";
      }
    }
  };

  handleScroll = () => {
    if (this.props.searchOpen) {
      this.props.fetchSearchMenuState(!this.props.searchOpen);
    } else {
      this.setState({ position: window.pageYOffset });
    }
  };

  handleBarPosition = () => {
    if (this.props.menuOpen === false) {
      if (this.state.position) {
        this.currentState = true;
        return this.currentState;
      }
      this.currentState = false;
      return this.currentState;
    }

    return this.currentState;
  };

  render() {
    return (
      <div className={`row nav-main ${(this.state.position && !this.props.menuOpen) || this.props.searchOpen ? "solid" : "hide"}`}>
        <div className={`col nav-gradient ${this.state.position || this.props.searchOpen ? "" : ""}`}></div>
        <div>
          <div
            className={`col d-flex justify-content-between nav-container  ${
              this.handleBarPosition() || this.props.searchOpen ? "move" : ""
            }`}
          >
            <div className="d-flex align-items-center ml-3 navbar-main-logo">
              <MainLogo />
            </div>
            <div className="py-5 my-2 mx-3 ms-md-2 pe-0 pe-sm-3 pe-md-5">
              <NavInfo />
            </div>
          </div>
          <div className={`nav-search ${this.props.searchOpen && !this.props.menuOpen ? "show" : `${this.hide} nav-hide`}`}>
            <div ref={this.wrapperRef}>
              <MainSearchBar />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
    searchOpen: state.searchOpen,
  };
};

export default connect(mapStateToProps, { fetchSearchMenuState })(NavBar);
