import React from "react";
import PageDataController from "../site-data/components/PageDataController";
import ClientProcessComp from "../components/ClientProcessComp";

import Slider from "../components/Slider";
import Flipper from "../components/Flipper";
import { Link } from "react-router-dom";


const Home = () => {
  document.title = "N&W | Welcome";

  return (
    <div className="">
      <div
        className="overflow-hidden"
        // className="container-fluid p-5 text-center"
      >
        <div className="videoContainer">
          <video autoPlay loop muted className="overflow-hidden frontvideo ">
            <source src="./img/DJI_0281.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      {/*<div className="container-lg">*/}
      {/*  <div className="row ">*/}
      {/*    <div className="col-xxl-12 mh-100 h-auto ">*/}
      {/*      <div className="d-flex justify-content-center pb-5">*/}
      {/*        <div*/}
      {/*          className="bg-white text-primary  position-absolute text-center"*/}
      {/*          style={{ fontFamily: "PT Serif Caption,serif", transform: "translateY(1.8rem) scale(1.3)" }}*/}
      {/*        >*/}
      {/*          <img src="/img/quote-alone.svg" className="" alt="quotation mark" />*/}
      {/*        </div>*/}
      {/*        <div className="border border-3 m-2 mt-5 p-3 border-info-dark" style={{ maxWidth: "700px" }}>*/}
      {/*          <p className="m-3 pt-4 px-4 text-center text-info h5 fw-light">*/}
      {/*            Law; an ordinance of reason for the common good, made by him who has care of the community.*/}
      {/*          </p>*/}
      {/*          <p className="text-end px-4 m-3 fst-italic text-info">Thomas Aquinas</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className=" container-fluid bg-lightGrey pt-5">
        <div className="row  ">
          <div className="col-12 d-flex justify-content-center">
            <div className="bg-light-grey p-3" style={{ maxWidth: "800px" }}>
              <div className="border-top border-bottom border-3 border-primary ms-4 me-4 mt-3">
                <div className="row d-flex justify-content-center p-4">
                  <div className="col-md-10">
                    <h3 className="text-center fw-light text-primary pb-2 pt-2">Practice Areas</h3>
                    <p className="text-center fw-light">
                      Our attorneys have years of combined experience with personal injury cases. From commercial accidents to toxic
                      exposure, we approach each case with skill and determination. Learn more about our areas of practice below.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <Slider />
          <div className="col-12">
            <div className="row mb-5 me-md-5">
              <div className="col-12 align-content-end bg-lightGrey d-flex justify-content-md-end justify-content-center">
                <Link to="/practice-areas" className="btn btn-primary rounded-0 py-3 px-4 pe-auto zindex-modal">
                  <span style={{ PointerEvent: "none" }}>View All Practice areas</span>
                </Link>
              </div>
            </div>
          </div>
          <Flipper data={new PageDataController().flipperData()} />
          <ClientProcessComp />
        </div>
      </div>
    </div>
  );
};

export default Home;
