const links = [
  {
    title: "Texas Trial Lawyers Association",
    href: "http://www.ttla.com/",
  },
  {
    title: "American Association of Justice",
    href: "https://www.google.com/search?sourceid=navclient&ie=UTF-8&rlz=1T4GGLL_enUS351US351&q=american+association+of+justice",
  },
  {
    title: "American Institute of Trial Lawyers",
    href: "https://aiotl.org/",
  },
  {
    title: "American Academy of Attorneys",
    href: "https://aaoaus.com/",
  },
  {
    title: "Smith County Bar Association",
    href: "http://www.smithcountybar.com/",
  },
  {
    title: "State Bar of Texas",
    href: "http://www.texasbar.com/am/template.cfm?section=home",
  },
  {
    title: "Northern",
    href: "http://www.txnd.uscourts.gov/",
  },
  {
    title: "Southern",
    href: "http://www.txs.uscourts.gov/",
  },
  {
    title: "Eastern",
    href: "http://www.txed.uscourts.gov/",
  },
  {
    title: "United States District Court, Eastern District of Texas",
    href: "http://www.txed.uscourts.gov/",
  },
  {
    title: "Texas A&M University",
    href: "https://www.tamu.edu/index.html",
  },
  {
    title: "South Texas College of Law",
    href: "http://www.stcl.edu/",
  },
  {
    title: "Baylor University",
    href: "https://www.baylor.edu/",
  },
  {
    title: "Baylor Law School",
    href: "https://www.baylor.edu/law/",
  },
  {
    title: "Baylor Bear Foundation",
    href: "http://bearfoundation.baylorbears.com/",
  },
  {
    title: "Texas Tech University",
    href: "http://www.ttu.edu/",
  },
  {
    title: "Texas Tech University School of Law",
    href: "http://www.law.ttu.edu/",
  },
  {
    title: "Federal Motor Carrier Regulations",
    href: "http://www.fmcsa.dot.gov/rules-regulations/administration/fmcsr/fmcsrguide.aspx?section_type=A",
  },
];

export const link = (text) => {
  const [linkData] = links.filter((el) => el.title === text);
  return (
    <a className="link text-primary" href={linkData.href}>
      {linkData.title}
    </a>
  );
};

export default link;
