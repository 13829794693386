import TopBar from "../components/TopBar";
import React from "react";
import NavBar from "../components/NavBar";
import Menu from "../components/Menu";
import CookieConsentContainer from "../components/CookieConsent";

export default class Header extends React.Component {
  render() {
    return (
      <div className=" container-fluid fixed-top zindex-sticky p-0">
        <CookieConsentContainer />
        <div className="row ">
          <TopBar />
        </div>
        <NavBar />
        <Menu />
      </div>
    );
  }
}
